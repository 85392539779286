import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators';
import http from '../../../libs/http';

const model = {
    id: 0,
    type: "Load-Manual-Subscriptions",
    created_at: null,
    file: "",
    runs: 0,
    started_at: null,
    stopped_at: null,
    multi: false,
    has_error: false,
    error: "",
    status: 0
};
const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, ""),
    modelValidation: {
        model: {
            file: {
                required
            }
        }
    },
    translationValidation: {},
    uploads: [
        {
            el: "uppyCsv",
            target: "file",
            folder: "/jobs/%Y/%M",
            protect: false,
            maxNum: 1,
            types: [tools.models.uploadFileTypes.CSV],
            urlChanger: url => {
                return url.replace(http.api.getS3bucket(),'')
            }
        },
    ]
};
